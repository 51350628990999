// This is an uncontrolled React form, which is way simpler than
// the normal React controlled form
// https://reactjs.org/docs/uncontrolled-components.html
//
// You can use browser form validation these days, and just
// get the values from the form on submit.

import React from "react";

import { FormRow, FormLabel, TextInput, TextArea } from "../../styles/forms";
import { dayjs } from "dayjs";

class PostForm extends React.Component {
  state = {
    type: "drink",
    startedOn: "",
    endedOn: "",
    comment: "",
  };

  componentDidMount() {
    const post = this.props.post;

    this.setState({
      type: post ? post.type : "drink",
      startedOn: post ? post.startedOn : dayjs().format("YY-MM-DDTHH:mm"),
      endedOn: post ? post.endedOn : "",
      comment: post ? (post.comment ? post.comment : "") : "",
    });
  }

  onSubmit = (event) => {
    const { type, startedOn, endedOn, comment } = event.target.elements;
    const values = {
      type: type.value,
      startedOn: startedOn.value,
      endedOn: endedOn.value,
      comment: comment.value,
    };
    event.preventDefault();
    this.props.onSubmit(values);
  };

  render() {
    return (
      <form onSubmit={this.onSubmit}>
        <FormRow>
          <FormLabel forHTML="type">Type</FormLabel>
          <select
            name="type"
            value={this.state.type}
            onChange={(e) =>
              this.setState({ ...this.state, type: e.target.value })
            }
          >
            <option value="drink">drinken</option>
            <option value="sleep">slapen</option>
          </select>
        </FormRow>

        <FormRow>
          <FormLabel forHTML="startedOn">Starttijd</FormLabel>
          <input
            type="datetime-local"
            name="startedOn"
            value={this.state.startedOn}
            onChange={(e) =>
              this.setState({ ...this.state, startedOn: e.target.value })
            }
          />
        </FormRow>

        <FormRow>
          <FormLabel forHTML="endedOn">Eindtijd</FormLabel>
          <input
            type="datetime-local"
            name="endedOn"
            value={this.state.endedOn}
            onChange={(e) =>
              this.setState({ ...this.state, endedOn: e.target.value })
            }
          />
        </FormRow>

        <FormRow>
          <FormLabel forHTML="comment">opmerking</FormLabel>
          <TextInput
            name="comment"
            value={this.state.comment}
            onChange={(e) =>
              this.setState({ ...this.state, comment: e.target.value })
            }
          />
        </FormRow>

        <button type="submit">Sla op</button>
      </form>
    );
  }
}

export default PostForm;
