import styled from "styled-components";
import { Link } from "react-router-dom";

import colors from "./colors";

const InternalLink = styled(Link)`
  color: ${colors.blue};
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
  margin-right: 16px;
`;
const HeaderLink = styled(Link)`
  color: ${colors.black};
  text-decoration: none;
  font-size: 1.1rem;
`;

export { InternalLink, HeaderLink };
