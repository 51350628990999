import dayjs from "dayjs";
import React from "react";
import { FirestoreCollection } from "react-firestore";
import styled from "styled-components";
import { Page } from "../../styles/layout";
import { InternalLink } from "../../styles/links";
import Error from "../misc/Error";
import updatePost from "../../actions/updatePost";
import deletePost from "../../actions/deletePost";
import createPost from "../../actions/createPost";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TH = styled.th`
  font-weight: bold;
  text-align: left;
  padding: 8px;
`;

const TD = styled.td`
  padding: 8px;
`;

const TR = styled.tr`
  border-bottom: 3px solid #fff;
`;

const PostList = ({ history }) => (
  <Page>
    <div style={{ marginTop: 16 }}>
      <button
        onClick={() =>
          createPost({
            type: "sleep",
            startedOn: dayjs().format("YYYY-MM-DDTHH:mm"),
          })
        }
        style={{ marginRight: 8, fontSize: 32, padding: "0px 16px" }}
      >
        😴
      </button>
      <button
        onClick={() =>
          createPost({
            type: "drink",
            startedOn: dayjs().format("YYYY-MM-DDTHH:mm"),
          })
        }
        style={{ marginRight: 8, fontSize: 32, padding: "0px 16px" }}
      >
        🍼
      </button>
      <button
        onClick={() => history.push("/new")}
        style={{ marginRight: 8, fontSize: 32, padding: "0px 16px" }}
      >
        ➕
      </button>
      <button
        onClick={() => history.push("/chart")}
        style={{ marginRight: 8, fontSize: 32, padding: "0px 16px" }}
      >
        📈
      </button>
    </div>
    <hr />
    <FirestoreCollection path={"posts"} sort="startedOn:desc">
      {({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return <p>loading...</p>;
        }

        if (data.length === 0) {
          return <p>Nog geen invoeren!</p>;
        }

        return (
          <Table>
            <thead></thead>

            <tbody>
              <TR>
                <TH>type</TH>
                <TH>datum</TH>
                <TH>starttijd</TH>
                <TH>eindtijd</TH>
                <TH>duur</TH>
                <TH></TH>
                <TH>opmerking</TH>
              </TR>
              {data.map((post) => {
                const startDate = new Date(post.startedOn);
                const endDate = new Date(post.endedOn);
                return (
                  <TR
                    key={post.id}
                    style={{
                      background: post.type === "sleep" ? "#eee" : "#f9f9f9",
                    }}
                  >
                    <TD>{post.type === "sleep" ? "😴" : "🍼"}</TD>
                    <TD>{dayjs(startDate).format("DD-MM")}</TD>
                    <TD>{dayjs(startDate).format("HH:mm")}</TD>
                    <TD>
                      {!isNaN(endDate) ? (
                        dayjs(endDate).format("HH:mm")
                      ) : (
                        <button
                          onClick={() =>
                            updatePost(post.id, {
                              endedOn: dayjs().format("YYYY-MM-DDTHH:mm"),
                            })
                          }
                        >
                          stop
                        </button>
                      )}
                    </TD>
                    <TD>
                      {!isNaN(endDate)
                        ? minutesToTimeString(
                            dayjs(post.endedOn).diff(post.startedOn, "minutes")
                          )
                        : ""}
                    </TD>
                    <TD>
                      <span
                        style={{
                          cursor: "pointer",
                          margin: "16px 8px",
                          display: "inline-block",
                        }}
                        onClick={() => deletePost(post)}
                      >
                        🗑️
                      </span>
                      <span
                        style={{ cursor: "pointer", margin: 8 }}
                        onClick={() => history.push(`/${post.id}/edit`)}
                      >
                        ✏️
                      </span>
                    </TD>
                    <TD>{post.comment}</TD>
                  </TR>
                );
              })}
            </tbody>
          </Table>
        );
      }}
    </FirestoreCollection>
  </Page>
);

function minutesToTimeString(totalMinutes) {
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes - hours * 60;

  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;

  return `${hours}:${minutes}`;
}

export default PostList;
