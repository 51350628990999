import React from 'react'

import {
  InternalLink,
} from '../../styles/links'

const SearchResult = ({ hit }) => (
  <InternalLink to={`/${hit.id}`}>
    {hit.title}
  </InternalLink>
)

export default SearchResult
