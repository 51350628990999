import React from "react";
import { FirestoreCollection } from "react-firestore";
import { Page } from "styles/layout";
import { InternalLink } from "styles/links";
import Error from "../misc/Error";

import { Chart as GChart } from "react-google-charts";
import dayjs from "dayjs";

export const Chart = ({ history }) => (
  <Page>
    <button
      onClick={() => history.push("/")}
      style={{ marginRight: 8, fontSize: 32, padding: "0px 16px" }}
    >
      📝
    </button>
    <hr />
    <FirestoreCollection path={"posts"} sort="startedOn:desc">
      {({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return <p>loading...</p>;
        }

        if (data.length === 0) {
          return <p>Nog geen invoeren!</p>;
        }
        return (
          <div>
            <GChart
              width={"100%"}
              height={"200px"}
              chartType="Timeline"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  { type: "string", id: "datum" },
                  { type: "string", id: "type" },
                  { type: "date", id: "Start" },
                  { type: "date", id: "End" },
                ],
                ...data
                  .reduce((acc, post) => {
                    if (post.endedOn === "") {
                      return acc;
                    }
                    if (
                      dayjs(post.startedOn).format("DD-MM") !==
                      dayjs(post.endedOn).format("DD-MM")
                    ) {
                      acc.push({
                        ...post,
                        startedOn: dayjs(post.endedOn)
                          .set("hour", 0)
                          .set("minute", 0),
                      });
                      acc.push({
                        ...post,
                        endedOn: dayjs(post.startedOn)
                          .set("hour", 23)
                          .set("minute", 59),
                      });
                    } else {
                      acc.push(post);
                    }
                    return acc;
                  }, [])
                  .map((post) => [
                    dayjs(post.startedOn).format("DD-MM"),
                    post.type === "sleep" ? "😴" : "🍼",
                    dayjs(post.startedOn)
                      .set("date", 1)
                      .set("year", 2021)
                      .set("month", 1),
                    dayjs(post.endedOn)
                      .set("date", 1)
                      .set("year", 2021)
                      .set("month", 1),
                  ]),
              ]}
              rootProps={{ "data-testid": "3" }}
            />
          </div>
        );
      }}
    </FirestoreCollection>
  </Page>
);
