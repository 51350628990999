import React from 'react'
import { FirestoreDocument } from 'react-firestore'
import deletePost from '../../actions/deletePost'
import updatePost from '../../actions/updatePost'
import {
  Page
} from '../../styles/layout'
import Error from '../misc/Error'
import PostForm from './PostForm'


const PostEdit = ({ match, history }) => (
  <Page>
    <FirestoreDocument
      path={'posts/' + match.params.id}
    >
      {({ error, isLoading, data }) => {
        if (error) {
          return <Error error={error} />
        }

        if (isLoading) {
          return <p>loading...</p>
        }

        if (data.length === 0) {
          return <Error />
        }

        const post = data

        return <div>
          <PostForm
            post={post}
            onSubmit={values => updatePost(post.id, values).then(() => history.push(`/`))}
          />
          <br />
          <button
            onClick={() => deletePost(post).then(() => history.push(`/`))}
          >Verwijder</button>
        </div>
      }}
    </FirestoreDocument>
  </Page>
)

export default PostEdit
